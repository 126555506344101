body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root{
  height: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.map {
  z-index: 0;
  position: relative;
}

.footer {
  z-index: 10;
  position: absolute;
  bottom: 50px;
  right: 5px;
  padding: 5px;
}

.header {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 50px;
}

.legend {
  z-index: 10;
  position: absolute;
  bottom: 100px;
  right: 5px;
  padding: 5px;
  width: 200px;
}

.title {
  color: rgb(0, 0, 0);
  text-shadow: 0 0 1px var(--shadow);
  font-size: 1.5rem;
  margin: 1rem 0;
  font-weight: var(--bold);
}

